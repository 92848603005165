<template>
    <div class="costomer-service-box">
        <div class="title clearfix" >
            <div class="tit-txt">客服热线 ： <span class="tel">13182987339</span>  </div>
        </div>
        <div>
            <!-- <div class="tel">
      
            </div> -->
            
        </div>
    </div>
    
</template>
<script>
    export default{
        data(){
            return {
                activeName:'first'
            }
        }
    }
</script>
<style lang="scss" scoped>
    .costomer-service-box{
        width:100%;
        min-height:600px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;


        .title{
            
            height:42px;
            line-height: 42px;
            border-bottom:2px solid #E4E7ED;
            text-align: left;
            padding:0 20px;

                .tel{
                    color:#1754BF;
                    font-weight: 600;
                }
    
        }

        // .tel{
        //     margin-top:10px;
        //     height:40px;
        //     line-height: 40px;
        //     font-size: 18px;
        //     text-align: left;
        //     padding:0 20px;
        // }
    }
</style>